import React, { Component } from "react"

import Glide from "@glidejs/glide";

import AccorHotelsGray from "../images/icons/clients/accor-hotels-gray.svg";
import AccorHotelsColor from "../images/icons/clients/accor-hotels-color.svg";
import TicketLogGray from "../images/icons/clients/ticket-log-gray.svg";
import TicketLogColor from "../images/icons/clients/ticket-log-color.svg";
import PhilcoGray from "../images/icons/clients/philco-gray.svg";
import PuketGray from "../images/icons/clients/puket-gray.svg";
import PuketColor from "../images/icons/clients/puket-color.svg";
import BritaniaGray from "../images/icons/clients/britania-gray.svg";
import BritaniaColor from "../images/icons/clients/britania-color.svg";
import ConsorcioEmbraconGray from "../images/icons/clients/consorcio-embracon-gray.svg";
import ConsorcioEmbraconColor from "../images/icons/clients/consorcio-embracon-color.svg";
import AguiaBrancaGray from "../images/icons/clients/aguiabranca-gray.svg";
import AguiaBrancaColor from "../images/icons/clients/aguiabranca-color.svg";
import QueroQuitarGray from "../images/icons/clients/quero-quitar-gray.svg";
import QueroQuitarColor from "../images/icons/clients/quero-quitar-color.svg";
import CasaAndVideoGray from "../images/icons/clients/casaandvideo-gray.svg";
import CasaAndVideoColor from "../images/icons/clients/casaandvideo-color.svg";

class ClientCarousel extends Component {
  componentDidMount () {
    new Glide('.glide-c', {
      animationDuration: 1000,
      animationTimingFunc: 'ease-in-out',
      autoplay: 4000,
      bound: true,
      breakpoints: {
        400: {
          perView: 1
        },
        800: {
          perView: 2
        },
        1100: {
          perView: 3
        },
        1400: {
          perView: 4
        }
      },
      hoverpause: false,
      keyboard: false,
      perView: 6,
      rewind: true,
      type: 'carousel'
    }).mount();
  }

  render () {
    return (
      <>
        <div className="clientCarousel pad-t-3 pad-b-3">
          <div class="glide-c glide">
            <div class="clientCarousel__track glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="clientCarousel__slide glide__slide">
                  <AccorHotelsGray />
                  <AccorHotelsColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <TicketLogGray />
                  <TicketLogColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <PhilcoGray />
                  <PhilcoGray />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <PuketGray />
                  <PuketColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <BritaniaGray />
                  <BritaniaColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <ConsorcioEmbraconGray />
                  <ConsorcioEmbraconColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <AguiaBrancaGray />
                  <AguiaBrancaColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <QueroQuitarGray />
                  <QueroQuitarColor />
                </li>
                <li class="clientCarousel__slide glide__slide">
                  <CasaAndVideoGray />
                  <CasaAndVideoColor />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ClientCarousel
